import * as React from "react"
import Allergenic from "./allergenic";
import "./menu.css"


const MenuTable = () => (
    <table className="menuTable">
        <tr>
            <td>
                <strong>Vorspeise</strong>
            </td>
            <td />
        </tr>
        <tr>
            <td>Randen-Falafel mit Hanfsamen und Tahini-Dressing</td>
            <td><Allergenic vegan dairyfree /></td>
        </tr>
        <tr>
            <td>Tomaten, weisse Bohnen, Hummus und Artischocken-Tapenade</td>
            <td><Allergenic vegan dairyfree glutenfree /></td>
        </tr>
        <tr>
            <td>Süsskartoffel&shy;pfannkuchen mit Kimchi-Mayo</td>
            <td><Allergenic vegan dairyfree /></td>
        </tr>
        <tr>
            <td>Kürbissuppen-Shots</td>
            <td><Allergenic glutenfree /></td>
        </tr>
        <tr>
            <td>
                <strong>Family Style Dinner</strong>
            </td>
            <td />
        </tr>

        <tr>
            <td>Mini-Tofu & Pilz-Baos</td>
            <td><Allergenic vegan dairyfree /></td>
        </tr>
        <tr>
            <td>Gemischter grüner Salat mit mazerierten frischen Feigen, zerbröckeltem Ziegenkäse und gerösteten Pinienkernen</td>
            <td><Allergenic glutenfree /></td>
        </tr>
        <tr>
            <td>Gebratener Kürbis mit gegrilltem Feta-Käse</td>
            <td><Allergenic glutenfree /></td>
        </tr>
        <tr>
            <td>Risotto mit frischem Trüffel vom Üetliberg</td>
            <td><Allergenic glutenfree /></td>
        </tr>
        <tr>
            <td>Mariniertes Blumenkohl&shy;steak</td>
            <td><Allergenic vegan dairyfree glutenfree /></td>
        </tr>
        <tr>
            <td>Gegrillter Chili, Knoblauch&shy;brokkoli</td>
            <td><Allergenic vegan dairyfree glutenfree /></td>
        </tr>
        <tr>
            <td>
                <strong>Dessert</strong>
            </td>
            <td />
        </tr>
        <tr>
            <td>Hochzeitstorte</td>
            <td></td>
        </tr>
        <tr>
            <td>Frischer Früchtesalat</td>
            <td><Allergenic vegan dairyfree glutenfree /></td>
        </tr>
    </table>
)
export default MenuTable
