import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";
import Vegan from "./vegan";
import GlutenFree from "./glutenFree";
import DairyFree from "./dairyFree";


const Allergenic = ({ vegan, glutenfree, dairyfree } = {}) => (
    <>
        { vegan ? <Vegan /> : null }
        { glutenfree ? <GlutenFree /> : null }
        { dairyfree ? <DairyFree /> : null }
    </>
)
export default Allergenic