import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";


const DairyFree = () => (
    <span title="Ohne Milch">
        <StaticImage
            src={`../images/illustrations/dairy_free.png`}
            loading="eager"
            height={20}
            quality={98}
            formats={["auto", "webp", "avif"]}
            alt="Ohne Milch"
            title="Ohne Milch"
        />
    </span>
)
export default DairyFree