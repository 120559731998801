import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner";
import Vegan from "../components/vegan";
import DairyFree from "../components/dairyFree";
import Allergenic from "../components/allergenic";
import MenuTable from "../components/menuTable";

const IndexPage = () => (
  <Layout>
    <Seo title="Menü" />
    <Banner illustration={
      <StaticImage
          src={`../images/illustrations/tl.webp`}
          loading="eager"
          height={128}
          quality={98}
          formats={["auto", "webp", "avif"]}
          alt="Plate with fork and knife"
      />
    } title="Menü" />
    <div>
      <MenuTable />
    </div>

  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Menü" />

export default IndexPage
