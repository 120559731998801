import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";


const GlutenFree = () => (
    <span title="Glutenfrei">
        <StaticImage
            src={`../images/illustrations/gf.png`}
            loading="eager"
            height={20}
            quality={98}
            formats={["auto", "webp", "avif"]}
            alt="Glutenfrei"
            title="Ohne Gluten"
        />
    </span>
)
export default GlutenFree